import React from "react";
import { useState } from "react";
import { collection, addDoc } from 'firebase/firestore';
import { db } from "../firebase";

const Form = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    mobile: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, 'requests'), formData);

      setFormData({
        name: '',
        email: '',
        subject: '',
        mobile: '',
        message: ''
      });

      alert('Data Submitted Successfully!!');
    } catch (error) {
      console.error('Error Adding the Document: ', error);
    }
    // Handle form submission here
    // const { name, email, subject, mobile, message } = formData;
    // if (name && email && subject && mobile && message) {
    //   const res = fetch(
    //     "https://alpha1-2a77b-default-rtdb.firebaseio.com/clientRecord.json",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         name,
    //         email,
    //         subject,
    //         mobile,
    //         message,
    //       }),
    //     }
    //   );

    //   if (res) {
    //     setFormData({
    //       name: "",
    //       email: "",
    //       subject: "",
    //       mobile: "",
    //       message: "",
    //     });
    //     alert("Data Stored");
    //   } else {
    //     alert("Please fill the form");
    //   }
    // } else {
    //   alert("please fill the form");
    // }
  };

  return (
    <form className="flex flex-col w-[75vw] max-sm:mt-[5vw]" method="POST">
      <div className="flex flex-row flex-wrap justify-between text-[1vw] max-sm:flex-col max-sm:text-[2.5vw]">
        <input
          className="w-[35vw] h-[4vw] rounded-lg pl-[2vw] mb-[2vw] shadow-textfield max-sm:w-[75vw] max-sm:h-[10vw] max-sm:pl-[5vw] max-sm:mb-[5vw]"
          type="text"
          name="name"
          placeholder="Name*"
          value={formData.name}
          onChange={handleChange}
        />
        <input
          className="w-[35vw] h-[4vw] rounded-lg pl-[2vw] mb-[2vw] shadow-textfield max-sm:w-[75vw] max-sm:h-[10vw] max-sm:pl-[5vw] max-sm:mb-[5vw]"
          type="email"
          name="email"
          placeholder="Email*"
          value={formData.email}
          onChange={handleChange}
        />
        <input
          className="w-[35vw] h-[4vw] rounded-lg pl-[2vw] mb-[2vw] shadow-textfield max-sm:w-[75vw] max-sm:h-[10vw] max-sm:pl-[5vw] max-sm:mb-[5vw]"
          type="text"
          name="subject"
          placeholder="Subject*"
          value={formData.subject}
          onChange={handleChange}
        />
        <input
          className="w-[35vw] h-[4vw] rounded-lg pl-[2vw] mb-[2vw] shadow-textfield max-sm:w-[75vw] max-sm:h-[10vw] max-sm:pl-[5vw] max-sm:mb-[5vw]"
          type="tel"
          name="mobile"
          placeholder="Mobile*"
          value={formData.mobile}
          onChange={handleChange}
        />
      </div>

      <div>
        <textarea
          className="w-full h-[4vw] rounded-lg pl-[2vw] pt-[1.5vw] text-[1vw] shadow-textfield max-sm:h-[25vw] max-sm:pl-[5vw] max-sm:pt-[4vw] max-sm:text-[2.5vw]"
          name="message"
          placeholder="Your Message*"
          value={formData.message}
          onChange={handleChange}
        ></textarea>

        <div className="flex items-center gap-[1vw] w-[40vw] h-[4vw] pl-[1vw] max-sm:w-full max-sm:mt-[2vw] max-sm:h-[8vw] max-sm:gap-[2.5vw]">
          <input
            type="checkbox"
            name="checkbox"
            value={formData.message}
            onChange={handleChange}
          />
          <p className="text-[1vw] max-sm:text-[2.5vw]">
            Accept terms of Service and Privacy Policy{" "}
          </p>
        </div>
      </div>

      <button
        className="w-[12vw] h-[4vw] text-white text-[1vw] font-medium bg-[#013A63] rounded mt-[0.5vw] hover:cursor-pointer hover:translate-x-[-0.2vw] hover:translate-y-[-0.2vw] hover:shadow-button transition duration-200 max-sm:w-[30vw] max-sm:h-[14vw] max-sm:text-[2.7vw] max-sm:mt-[4vw]"
        onClick={handleSubmit}
      >
        Send Message
      </button>
    </form>
  );
};

export default Form;
