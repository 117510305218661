import service1 from "../assets/service-monitoring.png";
import service2 from "../assets/service-compliance.png";
import service3 from "../assets/service-training.png";
import service4 from "../assets/service-water.svg";

import project1 from "../assets/water.png";
import project2 from "../assets/project-digital.png";
import project3 from "../assets/project-email.png";

import contact1 from "../assets/email.png";
import contact2 from "../assets/map.png";
import contact3 from "../assets/phone.png";

import posco from "../assets/logos/posco.png";
import volkswagen from "../assets/logos/volkswagen.png";
import sulzer from "../assets/logos/sulzer.png";
import tatablue from "../assets/logos/tatabluescope.png";
import cummins from "../assets/logos/cummins.png";
import columbiahospital from "../assets/logos/columbiahospital.png";
import atlas from "../assets/logos/atlas.jpg";
import aamby from "../assets/logos/aamby.jpg";
import cognizant from "../assets/logos/cognizant.png";

import profile from "../assets/profile.png";

import blogimage from "../assets/blogimage.svg";

function Data() {
  return {
    services: [
      {
        title: "ENVIRONMENTAL ENGINEERING & MONITORING services",
        content:
          "We provide cost-effective, scalable, environmental, regulatory compliant solutions. We offer turnkey servicesfrom planning, permitting, and design to construction oversight and monitoring.",
        image: service1,
        path: "environment",
      },
      {
        title: "SAFETY ENGINEERING & LEGAL COMPLIANCES",
        content:
          "We assist clients regarding regulatory frameworks and ensuring compliance with environmental, health, and safety laws. From risk management strategies to safety training programs, we provide comprehensive support.",
        image: service2,
        path: "compliance",
      },
      {
        title: "ONE WATER",
        content:
          "Our water treatment solutions ranges from traditional filtration systems to advanced membrane technologies, we tailor our approach to address specific water quality challenges, such as contamination, salinity, and nutrient runoff.",
        image: service4,
        path: "onewater",
      },
      {
        title: "SYSTEM & TRAINING",
        content:
          "We conduct comprehensive audits, including ISO, environmental, safety, electrical, energy, and fire audits. Our expertise extends to overseeing operations and maintenance of ETP, ZLD, STP, and CEM systems. We optimize pollution control for efficient performance.",
        image: service3,
        path: "training",
      },
      {
        title: "ENVIRONMENTAL CERTIFICATONS",
        content:
          "We specialize in delivering comprehensive environmental certification solutions tailored to meet regulatory standards. We ensure compliance with air and water quality regulations, waste management protocols, habitat preservation, etc.",
        image: service3,
        path: "certification",
      },
    ],

    environment: {
      title: "ENVIRONMENTAL ENGINEERING & MONITORING SERVICES",
      subtitle:
        "We work with clients to provide cost-effective, scalable environmental solutions that are implementable, regulatory compliant, and protective of human health and the environment. Through our centers of excellence, we offer a full range of turnkey services, from planning, permitting, and design to construction oversight and monitoring.",
      image: service1,
      subservices: [
        {
          title: "ETP Solutions",
          subtitle:
            "Effluent Treatment Plants design, supply, installation, and operation of sewage treatment plants.",
        },
        {
          title: "Compliance Management",
          subtitle:
            "Handling Pollution Control Board compliances, including consent management for establishment, renewals, and amendments also working towards environmental clearance and obtaining other obligatory approvals.",
        },
        {
          title: "Waste Management",
          subtitle:
            "Solid waste management services including green belt development, afforestation, land reclamation, water harvesting, and design of percolation dams and check dams",
        },
        {
          title: "EPR Solutions",
          subtitle:
            "Extended Producer Responsibility (EPR) is an environmental policy approach that holds producers accountable for the entire lifecycle of their products, particularly for take-back, recycling, and final disposal. Reduces waste and encourage sustainable product design.",
        },
        {
          title: "Monitoring Systems",
          subtitle:
            "Supply of online monitoring systems for Continuous Stack Emission and Treated Water Monitoring (CSEMS & ETP Treated Water) and assessment on the requirements on-line for CEMS and also look after all the installation and monitoring of all effective data.",
        },
        {
          title: "Environmental Analysis",
          subtitle:
            "Environment monitoring services provided through MOEF & NABL approved laboratories, offering analysis for ambient air, stack gases, work zone environment, water, wastewater, and hazardous waste.",
        },
        {
          title: "Treatability Studies",
          subtitle:
            "Expertise in treatability studies and waste management, focusing on waste reduction at the source, reuse, recycle, and ascertaining disposal paths to reduce disposal cost",
        },
        {
          title: "Sludge Conversion",
          subtitle: "ETP/STP Sludge to manure.",
        },
        {
          title: "Regulatory Submissions",
          subtitle:
            "Submission of form V(Environmental staement),Form IV (Hazardous waste return) And submission of Annual Form 27(DISH), RC Renewal and factory license & its renewal.",
        }
      ],
    },

    compliance: {
      title: "SAFETY ENGINEERING & LEGAL COMPLIANCES",
      subtitle:
        "We specialize in safety engineering and legal compliance services. We assist clients regarding regulatory frameworks and ensuring compliance with environmental, health, and safety laws. From risk management strategies to safety training programs, we provide comprehensive support to mitigate risks and much more.",
      image: service2,
      subservices: [
        {
          title: "Inspection Services",
          subtitle:
            "Testing and inspections of tools, tackles, pressure pipelines, vessels, and plants.",
        },
        {
          title: "Layout Approval",
          subtitle:
            "Preparation of plant layouts according to DISH norms for approvals and registrations from relevant departments",
        },
        {
          title: "Safety Engineering",
          subtitle:
            "Conducting safety engineering studies, audits, surveys, and reports; drafting Standard Operating Procedures (SOPs) for safe operations; performing Job Safety Analysis (JSA), Risk Analysis, Hazop studies, and preparation of Onsite Emergency Plans.",
        },
        {
          title: "Legal Compliance",
          subtitle:
            "Handling legal compliances under the Factory Act and Rules, including compliance with statutes such as Petroleum & SMPV Rules, Forest Conservation Act, Electricity Act & Rules, and Fire Prevention and Life Safety Measures Act 2006.",
        },
      ],
    },

    onewater: {
      title: "One Water",
      subtitle:
        "Our water treatment solutions encompass a range of innovative technologies and methodologies aimed at safeguarding water quality and promoting sustainability. From traditional filtration systems to advanced membrane technologies, we tailor our approach to address specific water quality challenges, such as contamination, salinity, and nutrient runoff. Our services include water quality assessments, treatment system design and much more..",
      image: service3,
      subservices: [
        {
          title: "Digital Water",
          subtitle:
            "Leveraging cutting-edge digital technologies for efficient water management, including real-time monitoring, data analytics, and predictive modeling to optimize water usage and infrastructure performance.",
        },
        {
          title: "Drinking Water",
          subtitle:
            "Implementing advanced treatment solutions to ensure the provision of safe and clean drinking water, addressing concerns such as contaminants, pathogens, and taste/odor issues.",
        },
        {
          title: "Emerging Contaminants",
          subtitle:
            "Developing strategies to identify and mitigate emerging contaminants in water sources, including pharmaceuticals, personal care products, and industrial chemicals, to safeguard public health and environmental integrity.",
        },
        {
          title: "Reuse",
          subtitle:
            "Promoting the reuse of treated wastewater for non-potable applications such as irrigation, industrial processes, and groundwater recharge, reducing freshwater demand and enhancing water sustainability.",
        },
        {
          title: "Stormwater",
          subtitle:
            "Implementing innovative stormwater management practices to mitigate flood risks, control pollution runoff, and enhance water quality in urban and industrial areas, integrating green infrastructure and best management practices.",
        },
        {
          title: "Wastewater",
          subtitle:
            "Providing comprehensive wastewater treatment solutions to address diverse pollutants and challenges, including biological treatment, advanced oxidation processes, and nutrient removal, ensuring compliance with regulatory standards and environmental protection.",
        },
        {
          title: "Water Resources Management",
          subtitle:
            "Developing holistic approaches for sustainable water resources management, including assessment of water availability, demand forecasting, integrated water planning, and stakeholder engagement to balance competing needs and ensure long-term water security.",
        },
      ],
    },

    training: {
      title: "System & Trainings",
      subtitle:
        "We conduct comprehensive audits, including ISO, environmental, safety, electrical, energy, and fire audits. Our expertise extends to overseeing operations and maintenance of ETP, ZLD, STP, and CEM systems. With a diverse team of environmentalists, engineers, and regulatory experts, we optimize pollution control equipment and ventilation systems for efficient performance, drawing from extensive experience in executive roles across various industries.",
      image: service3,
      subservices: [
        {
          title: "Audit Services",
          subtitle:
            "Conducting system audits including ISO audits, Environment audits, Safety audits, Electrical audits, Energy audits, and Fire audits; also performing Fire Load assessment also look after all the operations and maintainance of ETP, ZLD, STP, CEM",
        },
        {
          title: "Equipment Assessment",
          subtitle:
            "Studies on pollution control equipment/unit assessment for effective performance, covering Sewage Treatment Plants (STP), Effluent Treatment Plants (ETP), Hazardous Waste Treatment (HZWT), storage, handling, and other air pollution control systems; as well as ventilation systems (mechanized or conventional) and gas/smoke detection systems.",
        },
        {
          title: "Expert Team",
          subtitle:
            "Our team comprises environmentalists, engineers (chemical, mechanical), Chartered Engineers, and competent persons registered with regulatory bodies such as DISH, CCOE, Institute of Engineers, Directorate of Steam Boilers, etc. They have extensive experience in executive and managerial positions within various large companies, and they are currently involved in projects related to the aforementioned areas.",
        },
      ],
    },

    certification: {
      title: "Environmental Certifications",
      subtitle:
        "We specialize in delivering comprehensive environmental certification solutions tailored to meet regulatory standards. Our services ensure compliance with air and water quality regulations, waste management protocols, and habitat preservation measures. By partnering with us, businesses mitigate legal risks while enhancing their reputation as environmentally responsible entities committed to sustainable practices.",
      image: service3,
      subservices: [
        {
          title: "MPCB Consent & Compliances",
          subtitle:
            "Consent to Establish\nConsent to Operate\nConsent to Renewal\nE-Waste Authorization\nHazardous Waste Authorization\nBio-medical Waste Authorization\nPlastic Waste Authorization\nBattery Recycler Authorization\nE-waste Return\nOnline Logbook System\nBattery Return\nBio-medical Waste Annual Return\nHazardous Waste Annual Return (Form 4)\nEnvironmental Status Report (Form 5)\nOnline Manifest Submission(Form 10)\nExtended Producer Responsibility (EPR)",
        },
        {
          title: "Factory License & Compliances",
          subtitle:
            "Factory Plan Approval/Revision\nSafety Audit\nOn-site Emergency Plan\nEmergency Evacuation Plan\nHazard Identification & Risk Assessment\nFactory License\nFactory License Renewal\nAnnual Compliance Report(Form No. 27)\nEquipment Testing(Form No. 1 & 13)\nHAZOP Study\nMock Drills\nJob Safety Analysis",
        },
      ],
    },

    projects: [
      {
        id: 0,
        title: "One Water",
        subtitle: "One Water",
        image: project1,
        action: "https://example.com/action1",
        images: [
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/onewater%2FCopy%20of%200f08f70e-cf73-41eb-9d5b-b42ad1f11eda.JPG?alt=media&token=4103a2f0-a592-4146-8370-c2b45ce3495b",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/onewater%2FCopy%20of%20237ff8e0-7035-406e-b5e9-58d639139214.JPG?alt=media&token=5755db5b-20f5-443c-96fe-0132ca51c727",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/onewater%2FCopy%20of%20Copy%20of%20IMG-20180324-WA0038.jpg?alt=media&token=00edd528-824f-4036-aac7-ae9a98499793",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/onewater%2FCopy%20of%20Copy%20of%20IMG-20180506-WA0003.jpg?alt=media&token=cfc3c75c-70d0-42db-a751-7e942b87cb12",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/onewater%2FCopy%20of%20Copy%20of%20IMG-20180624-WA0020.jpg?alt=media&token=d802b438-8311-49aa-93cd-ee38565ad8cb",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/onewater%2FCopy%20of%20ad820526-9553-487e-b28b-ce0554777e4f.JPG?alt=media&token=0980d4c6-14f0-44d1-9c61-fa04b11685a4"
        ]
      },
      {
        id: 1,
        title: "Safety Engineering",
        subtitle: "Safety Engineering",
        image: project2,
        action: "https://example.com/action2",
        images: [
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/safety%2FCopy%20of%20Copy%20of%2020181202_161253.jpg?alt=media&token=14f94e3d-31ac-4dcf-a14a-dc1073925c91",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/safety%2FCopy%20of%20Copy%20of%20IMG-20180324-WA0038.jpg?alt=media&token=84356d3c-986f-4a6a-8b75-93d914886e5a",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/safety%2FCopy%20of%20Copy%20of%20IMG_20180531_112236.jpg?alt=media&token=2fac9cd3-0439-46a9-9701-2b7c82624e1c",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/safety%2FCopy%20of%20IMG_7582%202.jpg?alt=media&token=6c70358c-b3e8-4776-9383-dd911bc581df",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/safety%2FCopy%20of%20IMG_7587%202.jpg?alt=media&token=42c52cc4-d539-4fd6-b262-ec48a903fcf3",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/safety%2FCopy%20of%20IMG_7618%202.jpg?alt=media&token=94a66cbb-e925-44c7-8150-87e5f7789b56",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/safety%2FCopy%20of%20IMG_7637.jpg?alt=media&token=0ad4aa6a-b2fe-414c-afdc-4066e853d369",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/safety%2FCopy%20of%20IMG_7640.jpg?alt=media&token=d753f31c-341a-47b2-975e-b8f04b2d53bd"
        ],
      },
      {
        id: 2,
        title: "System & Training",
        subtitle: "System & Training",
        image: project3,
        action: "https://example.com/action3",
        images: [
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/systenandtraining%2FCopy%20of%20IMG-20180103-WA0013.jpg?alt=media&token=f24c00fe-db6f-40df-b23f-e93a394886b6",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/systenandtraining%2FCopy%20of%20IMG_2308.JPG?alt=media&token=a2331438-c377-4ecb-9b68-05a0caadb257",
          "https://firebasestorage.googleapis.com/v0/b/agroshield-61a8b.appspot.com/o/systenandtraining%2FCopy%20of%20IMG_2315.JPG?alt=media&token=c64be1b1-27ff-4c24-8146-e34eaae7c18d"
        ]
      },
    ],

    contact: [
      {
        title: "Mail Here",
        subtitle: "alphastatutorycompliance @gmail.com",
        image: contact1,
      },
      {
        title: "Visit Here",
        subtitle:
          "Office No.6, MPJ Chamber, Pune Mumbai Road, Wakdewadi Shivajinagar, Pune-411003",
        image: contact2,
      },
      {
        title: "Call Here",
        subtitle: "+91 9890629185",
        image: contact3,
      },
    ],

    partners: [
      {
        name: "Volkswagen",
        logo: volkswagen,
      },
      {
        name: "Tata Blue Scope Steel",
        logo: tatablue,
      },
      {
        name: "Sulzer",
        logo: sulzer,
      },
      {
        name: "Posco",
        logo: posco,
      },
      {
        name: "Cummins",
        logo: cummins,
      },
      {
        name: "Columbia Asia Hospital",
        logo: columbiahospital,
      },
      {
        name: "Cognizant",
        logo: cognizant,
      },
      {
        name: "Atlas Copco",
        logo: atlas,
      },
      {
        name: "Aamby Valley City",
        logo: aamby,
      },
    ],

    team: [
      {
        image: profile,
        position: "Director",
        name: " Mr. Sunil B. Mandhare",
      },
      {
        image: profile,
        position: "Executive Consultant, Auditor",
        name: "Mr. Shriram D. Bhalerao",
      },
      {
        image: profile,
        position: "Executive Consultant",
        name: "Mr. Ajay Deshmukh",
      },
      {
        image: profile,
        position: "Lab Incharge",
        name: "Mrs. N. S. Mandhare",
      },
      {
        image: profile,
        position: "Occupational Health specialist",
        name: "Dr. Prasad. S. Patil",
      },
      {
        image: profile,
        position: "B.Tech-Environment",
        name: "Mr. Sakshi S. Mandhare",
      },
    ],

    blog: [
      {
        image: blogimage,
        title: "The Industry Visit",
        subtitle:
          "This industry visit was performed for industry xyz on 23rd april this year. The goal was to collect information for auditing and forming compliances.",
      },
      {
        image: blogimage,
        title: "The Industry Visit",
        subtitle:
          "This industry visit was performed for industry xyz on 23rd april this year. The goal was to collect information for auditing and forming compliances.",
      },
      {
        image: blogimage,
        title: "The Industry Visit",
        subtitle:
          "This industry visit was performed for industry xyz on 23rd april this year. The goal was to collect information for auditing and forming compliances.",
      },
      {
        image: blogimage,
        title: "The Industry Visit",
        subtitle:
          "This industry visit was performed for industry xyz on 23rd april this year. The goal was to collect information for auditing and forming compliances.",
      },
      {
        image: blogimage,
        title: "The Industry Visit",
        subtitle:
          "This industry visit was performed for industry xyz on 23rd april this year. The goal was to collect information for auditing and forming compliances.",
      },
    ],
  };
}

export default Data;
