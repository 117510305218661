import React, { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../firebase';

const QueryForm = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    address: "",
    service: "",
    reason: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
       // Create an object that includes form data and additional fields
       const dataToPush = {
        ...formData,
        state: 'new', // Default value for state
        time: serverTimestamp() // Automatically sets the current time
      };
      // Add a new document with the form data to the Firebase collection
      await addDoc(collection(db, 'clientRequests'), dataToPush);
      alert("Data submitted successfully!");
      // Reset form after successful submission
      setFormData({
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        address: '',
        service: 'Service 1',
        reason: ''
      });
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Failed to submit data!");
    }
  };

  return (
    <div className="w-[35vw] h-[80vh] flex flex-col items-center gap-[1.4vw] max-sm:w-[70vw] max-sm:h-max">
      <input
        className="w-[30vw] h-[3.5vw] rounded-[1vw] text-[1vw] text-gray-400 pl-[2vw] shadow-textfield max-sm:w-[75vw] max-sm:h-[10vw] max-sm:pl-[5vw] max-sm:mb-[5vw] max-sm:text-[2.5vw] max-sm:rounded-[2vw]"
        type="text"
        name="firstname"
        placeholder="First Name"
        value={formData.firstname}
        onChange={handleChange}
        required
      />
      <input
        className="w-[30vw] h-[3.5vw] rounded-[1vw] text-[1vw] text-gray-400 pl-[2vw] shadow-textfield max-sm:w-[75vw] max-sm:h-[10vw] max-sm:pl-[5vw] max-sm:mb-[5vw] max-sm:text-[2.5vw] max-sm:rounded-[2vw]"
        type="text"
        name="lastname"
        placeholder="Last Name"
        value={formData.lastname}
        onChange={handleChange}
        required
      />
      <input
        className="w-[30vw] h-[3.5vw] rounded-[1vw] text-[1vw] text-gray-400 pl-[2vw] shadow-textfield max-sm:w-[75vw] max-sm:h-[10vw] max-sm:pl-[5vw] max-sm:mb-[5vw] max-sm:text-[2.5vw] max-sm:rounded-[2vw]"
        type="text"
        name="phone"
        placeholder="Phone No."
        value={formData.phone}
        onChange={handleChange}
        required
      />
      <input
        className="w-[30vw] h-[3.5vw] rounded-[1vw] text-[1vw] text-gray-400 pl-[2vw] shadow-textfield max-sm:w-[75vw] max-sm:h-[10vw] max-sm:pl-[5vw] max-sm:mb-[5vw] max-sm:text-[2.5vw] max-sm:rounded-[2vw]"
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <input
        className="w-[30vw] h-[3.5vw] rounded-[1vw] text-[1vw] text-gray-400 pl-[2vw] shadow-textfield max-sm:w-[75vw] max-sm:h-[10vw] max-sm:pl-[5vw] max-sm:mb-[5vw] max-sm:text-[2.5vw] max-sm:rounded-[2vw]"
        type="text"
        name="address"
        placeholder="Property Address"
        value={formData.address}
        onChange={handleChange}
        required
      />
      <select
        className="w-[30vw] h-[3.5vw] rounded-[1vw] text-[1vw] text-gray-400 pl-[2vw] shadow-textfield max-sm:w-[75vw] max-sm:h-[10vw] max-sm:pl-[5vw] max-sm:mb-[5vw] max-sm:text-[2.5vw] max-sm:rounded-[2vw]"
        name="service"
        value={formData.service}
        onChange={handleChange}
        required
      >
        <option value="" disabled>
          Select an option
        </option>
        <option value="Environmental Engineering and Monitoring Services">Environmental Engineering and Monitoring Services</option>
        <option value="Safety Engineering and Legal Compliances">Safety Engineering and Legal Compliances</option>
        <option value="One Water">One Water</option>
        <option value="System and Training">System and Training</option>
        <option value="Environmental Certification">Environmental Certification</option>
      </select>
      <input
        className="w-[30vw] h-[3.5vw] rounded-[1vw] text-[1vw] text-gray-400 pl-[2vw] shadow-textfield max-sm:w-[75vw] max-sm:h-[10vw] max-sm:pl-[5vw] max-sm:mb-[5vw] max-sm:text-[2.5vw] max-sm:rounded-[2vw]"
        type="text"
        name="reason"
        placeholder="Reason for Assessment"
        value={formData.reason}
        onChange={handleChange}
        required
      />

      <div className="w-full">
        <button onClick={handleSubmit} type="submit" className="w-[12vw] h-[4vw] text-white text-[1vw] font-medium ml-[3vw] bg-[#6A994E] rounded mt-[0.5vw] hover:cursor-pointer hover:translate-x-[-0.2vw] hover:translate-y-[-0.2vw] hover:shadow-button transition duration-200 max-sm:w-[30vw] max-sm:h-[14vw] max-sm:text-[2.7vw] max-sm:mx-[20vw]">
          Send Message
        </button>
      </div>
    </div>
  );
};

export default QueryForm;
