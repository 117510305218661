import React, { useState, useEffect, useRef } from "react";
import PullDown from "../assets/toggle-arrow.svg";
import BackButton from "../assets/back-arrow-green.svg";
import data from "../components/data";
import { Link, useParams } from "react-router-dom";
import QueryForm from "../components/queryform";
import Phone from "../assets/phone-green.svg";
import { useNavigate } from "react-router-dom";

const DetailedService = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [clicked, setClicked] = useState(true);
  function toggleBanner() {
    setClicked(!clicked);
  }

  const { path } = useParams();
  const jsonData = data()[`${path}`];

  const divRef = useRef(null);
  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setClicked(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full min-h-[100vh] bg-gradient-to-b from-white to-[#E1FFCC]">
      <div className="w-full min-h-[100vh] bg-detailedservice bg-cover">
        {/* dropdown menu */}
        <div
          ref={divRef}
          className="w-[22vw] flex flex-col items-center fixed z-20 left-[3vw] max-sm:w-[80vw] max-sm:left-[10vw]"
        >
          <div
            className="w-full bg-gradient-to-b from-[#386641] to-[#70CC82] rounded-b-[3vw] mb-[3vh] flex flex-col items-center shadow-banner z-[1] max-sm:rounded-b-[8vw] max-sm:mb-[2vh]"
            style={{
              transition: "all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
              height: clicked ? "83vh" : "6.5vw",
              overflow: "hidden",
            }}
          >
            <h2 className="text-[1.3vw] text-white mt-[2vw] font-semibold max-sm:text-[4vw] max-sm:mt-[10vw]">
              Sub Services
            </h2>
            <div className="w-[16vw] h-[.1vw] mt-[1vw] bg-white max-sm:w-[80%] max-sm:mt-[5vw]"></div>
            <div className="w-full h-[72vh] mt-[5vh] flex flex-col pb-[.5vw] pl-[1vw] items-center overflow-y-scroll scrollbar-thin scrollbar-thumb-[#386641] scrollbar-track-transparent max-sm:pb-[3vw]">
              {jsonData["subservices"].map((record) => (
                <div className="w-[19vw] min-h-[4.5vw] flex flex-col items-center p-[1.5vw] rounded-[.7vw] gap-[.7vw] group text-white font-light transition-all ease-in-out duration-300 overflow-hidden max-sm:w-[60vw] max-sm:min-h-[9vw] max-sm:rounded-[2vw] max-sm:p-[4.3vw] max-sm:gap-[2vw] hover:bg-white hover:text-[#386641] hover:font-semibold hover:cursor-pointer hover:min-h-[13vw] max-sm:hover:min-h-[30vw]">
                  <h3 className="text-[1vw] max-sm:text-[3.2vw]">
                    {record.title}
                  </h3>
                  <p className="text-[.8vw] font-light hidden min-h-[11vw] group-hover:block max-sm:text-[2.5vw]">
                    {record.subtitle.split("\n").map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <img
            src={PullDown}
            onClick={toggleBanner}
            className="h-[2vw] hover:cursor-pointer max-sm:h-[7vw]"
            alt="toggle button"
            style={{
              transition: "ease all .3s",
              rotate: clicked ? "0deg" : "180deg",
            }}
          />
        </div>
        {/* NavBar */}
        <div className="absolute z-10 w-[75vw] h-[7vw] right-0 flex gap-[2vw] items-center max-sm:w-full max-sm:h-[15vw] max-sm:top-[20vw] max-sm:gap-0">
          <img
            onClick={goBack}
            src={BackButton}
            className="w-[3vw] ml-[3vw] hover:cursor-pointer max-sm:w-[6vw] max-sm:ml-[7vw]"
            alt="Go Back"
          />
          <h1 className="text-[1.7vw] font-semibold text-[#386641] max-sm:w-[74vw] max-sm:text-center max-sm:text-[4vw]">
            {jsonData.title}
          </h1>
        </div>

        <div className="none max-sm:block max-sm:h-[30vw]"></div>
        {/* Actual Page Content */}
        <div className="w-full absolute z-0 bottom-0 flex items-center max-sm:flex-col max-sm:static ">
          {/* description */}
          <div className="w-[55vw] gap-[5vw] pl-[8vw] mr-[4vw] max-sm:text-center max-sm:p-0 max-sm:mt-[5vw] max-sm:w-full max-sm:px-[5vw] max-sm:mr-0 max-sm:flex max-sm:flex-col max-sm:items-center max-sm:mb-[7vw]">
            <h1 className="text-[3.5vw] leading-[4.4vw] mb-[1vw] font-bold text-[#386641] mt-[8vw] max-sm:text-[7vw] max-sm:leading-[9vw]">
              Your Environmental Business Partners
            </h1>
            <p className="text-[1.5vw] font-light max-sm:text-[3.5vw] max-sm:w-[60vw]">
              Alpha Statutory is a full-service environmental consulting firm,
              specializing in turnkey environmental solutions and environmental
              compliance.
            </p>
            <a
              href={`tel:9890629185`}
              className="flex items-center gap-[.5vw] text-[#386641] text-[1.1vw] font-semibold mt-[14vw] mb-[3vw] max-sm:hidden"
            >
              <p>For any Queries Call</p>
              <img src={Phone} className="w-[2vw]" img alt="phone" />
              <p>+91 9890629185</p>
            </a>
          </div>
          {/* query form */}
          <div className="flex flex-col items-center">
            <div className="flex items-center justify-center gap-[1vw] mb-[1vw] mt-[3vw] max-sm:gap-[2.5vw] max-sm:mb-[4.5vw]">
              <div className="w-[10vw] h-[1px] bg-[#386641] max-sm:w-[25vw]"></div>
              <p className="text-[#386641] text-[1.2vw] font-semibold max-sm:text-[3.2vw]">
                Enquiry Form
              </p>
              <div className="w-[10vw] h-[1px] bg-[#386641] max-sm:w-[25vw]"></div>
            </div>
            <QueryForm />
            <a
              href={`tel:9890629185`}
              className="hidden items-center gap-[2vw] text-[#386641] text-[2.5vw] font-semibold mt-[10vw] mb-[5vw] max-sm:flex"
            >
              <p>For any Queries Call</p>
              <img src={Phone} className="w-[5vw]" img alt="phone" />
              <p>+91 9890629185</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedService;
