import NavBar from "../components/nav";
import HomePage from "./home";
import ServicePage from "./services";
import ProjectsPage from "./projects";
import AboutUsPage from "./aboutus";
import OurPartners from "./partners";
import Banner from "../components/banner";
import Contact from "./contact";
import Team from "./team";
import Blog from "./blog";

export default function Main() {
  return (
    <div className="w-full h-full bg-gradient-to-b from-white to-[#E1FFCC] font-poppins overflow-x-hidden scrollbar-thin scrollbar-thumb-[#386641] scrollbar-track-transparent scroll-smooth">
      <NavBar />
      <div id="home"></div>
      <HomePage />
      <div id="services"></div>
      <ServicePage />
      <div id="projects"></div>
      <ProjectsPage />
      <div id="about"></div>
      <AboutUsPage />
      <div id="team"></div>
      <Team />
      <div id="partners"></div>
      <OurPartners />
      <div id="blog"></div>
      {/* <Blog/> */}
      <Banner />
      <div id="contact"></div>
      <Contact />
    </div>
  );
}
